<template>
	<div class="mhome">
		<div class="mhome-wrap" v-infinite-scroll="handleScroll">
			<div class="mhome-head">
				<div class="user-item">
					<img class="item-pic" :src="userInfo.portrait" @error="avatarLoadError" alt="">
					<div class="item-block">
						<div class="name">{{ userInfo.userName }}</div>
						<div class="text">{{ userInfo.mobile }}</div>
					</div>
				</div>
			</div>
			<div class="mhome-tabs">
				<div class="item" :class="{ active: isActive('await') }" @click="tabsChange('await')">{{ $t('mobile.actions.orderEvaluate') }}({{ pageTotal }})</div>
				<div class="item" :class="{ active: isActive('have') }" @click="tabsChange('have')">{{ $t('mobile.actions.hasEvaluate') }}</div>
			</div>
			<div class="mhome-list" v-if="tabsActive === 'await'">
				<div class="item" v-for="(item, index) in orderList" :key="index">
					<div class="item-head">
						<router-link :to="{ name: 'mStoreInfo', params: { id: item.store_id } }" class="name">{{ item.store_name }}<i class="el-icon-arrow-right"></i></router-link>
					</div>
					<router-link :to="{ name: 'mOrderInfo', params: { id: item.order_id } }" class="item-goods">
						<div class="cell" v-for="(goods, index2) in item.orderGoods" :key="index2">
							<img class="cell-pic" :src="goods.goods_image" alt="">
							<div class="cell-main">
								<div class="name">{{ goods.goods_name }}</div>
								<div class="desc">{{ goods.specification }}</div>
							</div>
						</div>
					</router-link>
					<div class="item-bottom">
						<div></div>
						<div class="actions">
							<router-link class="btn btn-red" :to="{ name: 'mOrderEvaluate', params: { id: item.order_id } }">{{ $t('mobile.actions.evaluate') }}</router-link>
						</div>
					</div>
				</div>
			</div>
			<div class="mhome-list" v-else>
				<div class="item" v-for="(item, index) in evaluateList" :key="index">
					<div class="item-head">
						<div class="time">{{ item.goods_evaluate.create_time | parseTime('{y}-{m}-{d}') }}</div>
						<div class="type" @click="handleEditStatus(item.goods_evaluate)">
							<svg-icon :icon-class="item.goods_evaluate.is_nick_name !== 1 ? 'no_eye' : 'eye'"></svg-icon>
							{{ item.goods_evaluate.is_nick_name | statusFilter($t('mobile.filters.evaluateStatus')) }}
						</div>
					</div>
					<div class="item-text">{{ item.goods_evaluate.content || $t('mobile.evaluate.text1') }}</div>
					<div class="item-goods">
						<div class="cell have-goods">
							<router-link :to="{ name: 'mGoodsInfo', params: { id: item.goods_id } }" class="cell-pic">
								<img :src="item.goods_image" alt="" />
							</router-link>
							<div class="cell-main">
								<div class="name">{{ item.goods_name }}</div>
								<div class="desc">{{ item.specification }}</div>
							</div>
						</div>
					</div>
					<div class="item-bottom">
						<!-- <div class="census">
							<span>浏览<em>0</em></span>
							<span>点赞<em>0</em></span>
							<span>评论<em>0</em></span>
						</div>
						<div class="actions">
							<div class="btn btn-red">{{ $t('mobile.actions.followUpEvaluate') }}</div>
						</div> -->
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { mapGetters } from 'vuex'
import { getOrderList, fetchEvaluateList, anonymityEvaluate } from '@/api/mobile/order'
export default {
	computed: {
		...mapGetters([
			'userInfo'
		])
	},
	filters: {
		statusFilter(status = 0, option) {
			return option[status]
		}
	},
	data() {
		return {
			orderList: [],
			evaluateList: [],
			pageCurr: 1,
			pageSize: 10,
			pageflag: true,
			pageTotal: 0,
			isScroll: false,
			tabsActive: 'await'
		}
	},
	created() {
		this.tabsActive = this.$route.params.type || 'await'
		if (this.tabsActive === 'have') {
			this.getOrderList()
		}
		this.tabsChange(this.$route.params.type)
	},
	methods: {
		avatarLoadError(e) {
			e.target.src = this.$imageErrorUser;
			e.target.style.objectFit = 'fill';
		},
		getOrderList() {
			let loading = this.$loading()
			const params = {
				page_index: (this.pageCurr - 1) * this.pageSize,
				page_size: this.pageSize,
				status: 40
			}
			getOrderList(params).then(response => {
				loading.close()
				if (response.data.list.length > 0) {
					this.orderList = this.orderList.concat(response.data.list);
				} else {
					this.pageflag = false;
				}
				this.pageTotal = response.data.count
			})
		},
		getEvaluateList() {
			let loading = this.$loading()
			const params = {
				page_index: (this.pageCurr - 1) * this.pageSize,
				page_size: this.pageSize
			}
			fetchEvaluateList(params).then(response => {
				loading.close()
				if (response.data.list.length > 0) {
					this.evaluateList = this.evaluateList.concat(response.data.list);
				} else {
					this.pageflag = false;
				}
			})
		},
		isActive(value) {
			const type = this.tabsActive || 'await'
			return type === value
		},
		tabsChange(value) {
			this.tabsActive = value || 'await'
			this.orderList = []
			this.evaluateList = []
			this.pageCurr = 1
			this.pageflag = true
			if (value !== 'have') {
				this.getOrderList()
			} else {
				this.getEvaluateList()
			}
		},
		handleScroll() {
			if (this.isScroll) {
				this.pageCurr++;
				if (this.pageflag) {
					if (this.tabsActive !== 'have') {
						this.getOrderList()
					} else {
						this.getEvaluateList()
					}
				}
			} else {
				this.isScroll = true
			}
		},
		handleEditStatus(data) {
			let text = this.$t('mobile.confirm.anonymityText1')
			let anonymity = 0
			if(data.is_nick_name !== 1) {
				text = this.$t('mobile.confirm.anonymityText2')
				anonymity = 1
			}
			this.$confirm(text,'',{
				confirmButtonText: this.$t('mobile.actions.confirm'),
				cancelButtonText: this.$t('mobile.actions.cancel'),
				customClass: 'wap-message-confirm',
				showClose: false,
				closeOnClickModal: false
			}).then(() => {
				const params = {
					goods_evaluate_id: data.goods_evaluate_id,
					is_nick_name: anonymity,
				}
				anonymityEvaluate(params).then(() => {
					data.is_nick_name = anonymity
					let msg = this.$t('mobile.successMsg.anonymityText1')
					if(anonymity !== 1) {
						msg = this.$t('mobile.successMsg.anonymityText2')
					}
					this.$message({
						message: msg,
						customClass: 'wap-message',
						type: 'success'
					});
				})
			}).catch(() => {})
		}
	}
}
</script>

<style lang="scss" scoped>
.mhome-head {
	padding: 0.512rem 0.768rem;
	margin-bottom: 0.192rem;

	.user-item {
		display: flex;
		align-items: center;

		.item-pic {
			flex-shrink: 0;
			width: 1.536rem;
			height: 1.536rem;
			border-radius: 50%;
			background-color: rgba(255, 255, 255, .5);
			box-shadow: 0 0 0 0.064rem rgba(255, 255, 255, .5) inset;
		}

		.item-block {
			flex: 1;
			width: 0;
			margin-left: 0.32rem;

			.name {
				color: #333333;
				font-size: 0.544rem;
				line-height: 0.768rem;
			}

			.text {
				opacity: .8;
				color: #777777;
				font-size: 0.352rem;
				margin-top: 0.192rem;
			}
		}
	}
}

.mhome-tabs {
	border-radius: 0.256rem 0.256rem 0 0;
	background-color: #ffffff;
	display: flex;
	padding: 0.16rem 0;

	.item {
		width: 25%;
		flex-shrink: 0;
		text-align: center;
		font-size: 0.448rem;
		line-height: 0.64rem;
		padding: 0.16rem 0;
		position: relative;

		&.active {
			font-weight: 600;

			&::after {
				bottom: 0;
				left: 50%;
				content: "";
				width: 0.384rem;
				height: 0.064rem;
				margin-left: -0.192rem;
				background-color: #333333;
				position: absolute;
			}
		}
	}
}

.mhome-list {
	padding: 0.32rem;

	.item {
		margin-bottom: 0.32rem;
		border-radius: 0.256rem;
		background-color: #ffffff;

		.item-head {
			display: flex;
			padding: 0.384rem;
			line-height: 0.544rem;
			justify-content: space-between;

			.name {
				color: #333333;
				font-size: 0.448rem;
				font-weight: bold;

				i {
					color: #999999;
				}
			}

			.time {
				font-size: 0.384rem;
				color: #999999;
			}

			.type {
				color: #999999;
				font-size: 0.384rem;

				.svg-icon {
					margin-right: 0.096rem;
				}
			}
		}

		.item-text {
			padding: 0 0.384rem;
			font-size: 0.416rem;
			line-height: 0.544rem;
			margin-bottom: 0.32rem;
		}

		.item-goods {
			display: block;
			padding: 0 0.384rem;

			.cell {
				display: flex;
				margin-bottom: 0.32rem;

				.cell-pic {
					flex-shrink: 0;
					width: 2.176rem;
					height: 2.176rem;
					border-radius: 0.32rem;
					object-fit: cover;
				}

				.cell-main {
					flex: 1;
					width: 0;
					margin: 0 0.32rem;

					.name {
						color: #333333;
						font-size: 0.416rem;
						height: 0.768rem;
						line-height: 0.768rem;
						overflow: hidden;
						text-overflow: ellipsis;
						white-space: nowrap;
						font-weight: 600;
					}

					.desc {
						max-height: 1.088rem;
						line-height: 0.544rem;
						color: #999999;
						font-size: 0.416rem;
						display: -webkit-box;
						overflow: hidden;
						text-overflow: ellipsis;
						-webkit-box-orient: vertical;
						-webkit-line-clamp: 2;
						white-space: normal;
					}
				}

				.cell-box {
					flex-shrink: 0;
					text-align: right;

					.price {
						height: 0.768rem;
						line-height: 0.768rem;
						color: #333333;
						font-weight: 600;
						font-size: 0.384rem;

						span {
							font-size: 0.448rem;
						}
					}

					.number {
						height: 0.544rem;
						line-height: 0.544rem;
						color: #999999;
						font-size: 0.384rem;
					}
				}

				&:last-child {
					margin-bottom: 0;
				}

				&.have-goods {
					border-radius: 0.32rem;
					background-color: #fbfbfb;
					border: 1px solid #fbfbfb;

					.cell-pic {
						width: 1.92rem;
						height: 1.92rem;
						overflow: hidden;

						img {
							width: 100%;
							height: 100%;
							object-fit: cover;
						}
					}

					.cell-main {
						padding: 0.192rem 0;

						.desc {
							max-height: 0.544rem;
							-webkit-line-clamp: 1;
						}
					}
				}
			}
		}

		.item-bottom {
			padding: 0.384rem;
			display: flex;
			align-items: center;
			justify-content: space-between;

			.census {
				font-size: 0.384rem;

				span {
					margin-right: 0.192rem;
					color: #999999;

					em {
						margin-left: 0.096rem;
					}
				}
			}

			.actions {

				.btn {
					min-width: 1.504rem;
					color: #333333;
					font-size: 0.416rem;
					flex-shrink: 0;
					text-align: center;
					height: 0.544rem;
					line-height: 0.544rem;
					border: 1px solid #ddd;
					padding: 0.16rem 0.32rem;
					border-radius: 0.64rem;
					margin-left: 0.32rem;
					display: inline-block;

					&.btn-red {
						color: #ff5000;
						border-color: #ff5000;
					}
				}
			}
		}
	}
}
</style>